
<template>
  <div class="main-content">
    <loading :active.sync="isLoading"
             :is-full-page="fullPage"></loading>

    <b-container>
      <b-card  title="Create Fraud Rule" class="card-icon mb-30 p-16">

        <form action="#" class="form-horizontal "  method="post" v-on:submit.prevent="validateBeforeSubmitRule">
          <b-row>
            <b-col lg="12" md="12" sm="12">
              <div >
                <div class="row">
                  <div class="col-6">
                    <div class="form-group">
                      <label><strong>Rule Name</strong></label> <br/>
                      <input type="text" class="form-control" v-model="rule.ruleName" />
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="form-group">
                      <label><strong>Description</strong></label> <br/>
                      <input type="text" class="form-control" v-model="rule.description" />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-6">
                    <div class="form-group">
                      <label><strong>Error Message</strong></label> <br/>
                      <input type="text" class="form-control" v-model="rule.errorMessage" />
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="form-group">
                      <label><strong>Activity Type: </strong></label> <br/>
                      <select class="form-control" v-model="rule.activityType">
                        <option value="ACCRUAL"> Accrual </option>
                        <option value="REDEMPTION"> Redemption </option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-6">
                    <div class="form-group">
                      <label><strong>Status:</strong></label> <br/>
                      <label for="rule-active">
                        <strong><input id="rule-active" type="radio" class="form-check" v-model="rule.status" value="ACTIVE" /> Active </strong>
                      </label>
                      <label class="ml-5" for="rule-inactive">
                        <strong><input id="rule-active" type="radio" class="form-check" v-model="rule.status" value="INACTIVE" /> Inactive </strong>
                      </label>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="form-group">
                      <label><strong>Rule Logic: </strong></label> <br/>
                      <select class="form-control" v-model="rule.ruleLogic">
                        <option value="AND"> AND </option>
                        <option value="OR"> OR </option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-6">
                    <div class="form-group">
                      <label><strong>Action Type: </strong></label> <br/>
                      <select class="form-control" v-model="rule.actionType">
                        <option value="FLAG"> Flag </option>
                        <option value="REJECT"> Reject </option>
                      </select>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="form-group">
                      <label><strong>Duration (In Days)</strong></label> <br/>
                      <input type="number" class="form-control" v-model="rule.duration" />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-6">
                    <div class="form-group">
                      <label><strong>Category: </strong></label> <br/>
                      <select class="form-control" v-model="rule.category">
                        <option value="ALL"> All </option>
                        <option value="OUTLET"> Outlet </option>
                      </select>
                    </div>
                  </div>
                  <div class="col-6" v-if="rule.category == 'OUTLET'">
                    <div class="form-group">
                      <label><strong>Outlet: </strong></label> <br/>
                      <select class="form-control" v-model="rule.outletId">
                        <option value="0"> -- Select -- </option>
                        <option :value="outlet.id" v-for="outlet in outlets"> {{ outlet.name }} </option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <label><strong>Rules</strong></label> <br/>
                    <table class="table table-bordered">
                      <tr>
                        <th>Criteria</th>
                        <th>Operation</th>
                        <th>Value</th>
                        <th>Options</th>
                      </tr>
                      <tr v-for="(rule, index) in rule.rules">
                        <td>
                          <select class="form-control" v-model="rule.criteria">
                            <option value=""> -- Select -- </option>
                            <option value="TOTAL_ACCRUAL_AMOUNT">Total Accrual Amount </option>
                            <option value="TOTAL_ACCRUAL_BASE_POINTS">Total Accrual Base Points </option>
                            <option value="TOTAL_ACCRUAL_BONUS_POINTS">Total Accrual Bonus Points </option>
                            <option value="TOTAL_ACCRUAL_POINTS">Total Accrual Points </option>
                            <option value="TOTAL_ACCRUAL_TRANSACTION_COUNT">Total Accrual Transaction Count </option>
                            <option value="TOTAL_REDEMPTION_AMOUNT">Total Redemption Amount </option>
                            <option value="TOTAL_REDEMPTION_TRANSACTION_COUNT">Total Redemption Transaction Count </option>
                          </select>
                        </td>
                        <td>
                          <select class="form-control" v-model="rule.equality">
                            <option value=""> -- Select -- </option>
                            <option value="EQUAL"> Equal </option>
                            <option value="GREATER_THAN">Greater Than </option>
                            <option value="GREATER_THAN_OR_EQUAL">Greater than or equal </option>
                            <option value="LESS_THAN">Less than </option>
                            <option value="LESS_THAN_OR_EQUAL">Less than or equal </option>
                          </select>
                        </td>
                        <td>
                          <input type="number" class="form-control" v-model="rule.value">
                        </td>
                        <td>
                          <b-button
                              type="button"
                              @click="removeRule(index)"
                              variant="danger"
                              class="btn-icon"
                          >
                            <feather-icon icon="ArchiveIcon" />
                          </b-button>
                        </td>
                      </tr>
                    </table>

                    <br/>

                    <button type="button" @click="addRule" class="btn btn-warning btn-sm float-right">Add Rule</button>
                  </div>
                </div>
              </div>

              <div class="mt-2 mb-2" >
                <hr/>
              </div>
              <div >
                <button class="btn btn-success float-right" type="submit" >Submit</button>
              </div>
            </b-col>
          </b-row>
        </form>
      </b-card>
    </b-container>

  </div>
</template>
<script>

  import { mapGetters, mapActions } from "vuex";
  import DatePicker from 'vue2-datepicker';
  import 'vue2-datepicker/index.css';
  import Loading from 'vue-loading-overlay';
  import 'vue-loading-overlay/dist/vue-loading.css'
  import Multiselect from 'vue-multiselect';
  import vSelect from 'vue-select'
  import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
  import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
  import Button from "@/views/components/button/Button.vue";
  import Table from "@/views/table/bs-table/Table.vue";

export default {
  data() {
    return {
      outletProduct:"",
      selectMode: 'multi',
      days:["MON","TUE","WED","THU","FRI","SAT","SUN"],
      disabled:false,
      isLoading: false,
      fullPage: true,
      outlets:[],
      partners:[],
      products:[],
      outlet_columns:["name"],
      rule:{
        id:0,
        ruleName:"",
        description:"",
        errorMessage:"",
        status:"",
        activityType:"",
        actionType:"",
        ruleLogic:"",
        rules:[],
        duration:1,
        category:"",
        outletId:0
      },
      selectedOutlets:[],
      selectedProducts:[]
    };
  },
  components: {
    Table,
    Button,
    Loading,
    DatePicker,
    Multiselect,
    vSelect
  },
  mounted (){
    //this.getPartnersData();
    this.getOutletsData();
  },
  methods: {
    ...mapActions(["createFraudRule",'getOutlets']),

    getOutletsData(){
      let self = this;
      this.getOutlets()
          .then(function (outlets) {
            self.outlets = outlets;
          })
          .catch(function (error) {

          })
    },
    validateBeforeSubmitRule() {
      this.saveRule();
    },
    addRule(){
      this.rule.rules.push({
        value:"",
        criteria:"",
        equality:""
      });
    },
    removeRule(index) {
      this.rule.rules.splice(index,1)
    },

    saveRule() {
      let self = this;

      console.log(this.rule);

      this.isLoading = true;

      this.createFraudRule(this.rule).then(() => {
        self.isLoading = false;

        self.$bvToast.toast("Rule saved.", {
          title: 'Success',
          variant: 'success',
          autoHideDelay: 5000,
          appendToast: false
        });

        this.$router.push("/apps/fraudRules");


      })
          .catch(error => {

            self.isLoading = false;

            self.$bvToast.toast(error.message, {
              title: 'Error',
              variant: 'danger',
              autoHideDelay: 5000,
              appendToast: false
            });

            /*if( typeof error.response.status != "undefined"){
               if(error.response.status == 401){
                 location.replace("/app/sessions/signIn");
               }

            }
            else{




            }*/
          })
    }
  },

};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
